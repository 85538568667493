/* eslint-disable no-await-in-loop */

import React, { Component } from 'react';
import {
  uniqBy, cloneDeep, mean, sum, round, max
} from 'lodash';
import moment from 'moment';
import {
  Segment, Divider, Dropdown, Checkbox, Button
} from 'semantic-ui-react';
import { Chart } from 'react-google-charts';

import Avatar from './Avatar';

import utils from '../lib/utils';

import '../assets/App.css';

const date = moment();

const styles = {
  title: {
    color: 'black',
    fontWeight: 'bold'
  },
  coded: {
    color: '#0e8a16',
    fontWeight: 'bold'
  },
  passed: {
    color: '#0052cc',
    fontWeight: 'bold'
  },
  review: {
    color: '#fbca04',
    fontWeight: 'bold'
  },
  beta: {
    color: '#95f490',
    fontWeight: 'bold'
  }
};

const typeOptions = () => ([
  {
    key: 'bug', text: 'Bug', value: 'bug', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'enhancement', text: 'Enhancement', value: 'enhancement', color: { backgroundColor: '#bfd4f2', color: 'black' }
  },
  {
    key: 'feature', text: 'Feature', value: 'feature', color: { backgroundColor: '#bfd4f2', color: 'black' }
  }
]);

const priorityOptions = () => ([
  {
    key: 'high', text: 'High', value: 'priority: high', color: { backgroundColor: '#b60205', color: 'white' }
  },
  {
    key: 'normal', text: 'Normal', value: 'priority: normal', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'low', text: 'Low', value: 'priority: low', color: { backgroundColor: '#f9d0c4', color: 'black' }
  },
]);

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repoStats: null,
      issues: null,
      users: null,
      selectedTypes: ['bug'],
      selectedPriorities: [],
      withLabels: [],
      withoutLabels: ['beta'],
      openInfo: true,
      labels: []
    };
  }

  getIssueInfo = (issue) => {
    const node = cloneDeep(issue);

    node.tracking = utils.trackIssueLabels(node);

    return node;
  }

  getIssuesStats = (issues) => {
    const stats = {};

    stats.timeToAssign = { all: [] };
    issues.forEach((issue) => {
      const diffs = Object.values(issue.timelineAssign).map(dt => moment(dt).diff(issue.createdAt, 'days'));

      issue.assignees.nodes.forEach((assignee) => {
        if (issue.timelineAssign[assignee.login] > issue.timelineTracking.coded) return;

        const diff = moment(issue.timelineAssign[assignee.login]).diff(issue.createdAt, 'days');

        if (!stats.timeToAssign[assignee.login]) {
          stats.timeToAssign[assignee.login] = [diff];
        } else {
          stats.timeToAssign[assignee.login].push(diff);
        }
      });

      stats.timeToAssign.all.push(diffs.length ? max(diffs) : null);
    });
    Object.keys(stats.timeToAssign).forEach((key) => {
      stats.timeToAssign[key] = mean(stats.timeToAssign[key].filter(value => typeof value === 'number' && value >= 0)) || null;
    });

    stats.timeToCode = mean(issues
      .map(issue => !!issue.timelineTracking.coded && moment(issue.timelineTracking.coded).diff(issue.createdAt, 'days'))
      .filter(value => typeof value === 'number' && value >= 0)) || null;
    stats.timeToBeta = mean(issues
      .map(issue => !!issue.timelineTracking.stage && !!issue.timelineTracking.coded && moment(issue.timelineTracking.stage).diff(issue.timelineTracking.coded, 'days'))
      .filter(value => typeof value === 'number' && value >= 0)) || null;
    stats.timeToTest = mean(issues
      .map(issue => !!issue.timelineTracking.passed && !!issue.timelineTracking.stage && moment(issue.timelineTracking.passed).diff(issue.timelineTracking.stage, 'days'))
      .filter(value => typeof value === 'number' && value >= 0)) || null;
    stats.timeToLaunch = mean(issues
      .map(issue => !!issue.timelineTracking.launched && !!issue.timelineTracking.passed && moment(issue.timelineTracking.launched).diff(issue.timelineTracking.passed, 'days'))
      .filter(value => typeof value === 'number' && value >= 0)) || null;
    stats.timeToReview = mean(issues
      .map(issue => !!issue.timelineTracking.reviewFirst && !!issue.timelineTracking.coded && moment(issue.timelineTracking.reviewFirst).diff(issue.timelineTracking.coded, 'days'))
      .filter(value => typeof value === 'number' && value >= 0)) || null;

    return stats;
  }

  getRepoStats = (issues, filterWith, filterWithout, withAssigneesOnly = false) => {
    const stats = {
      thisMonth: {},
      oneMonthAgo: {},
      twoMonthsAgo: {},
      threeMonthsAgo: {},
    };

    if (filterWith) {
      issues = utils.filterIssuesWithLabel(filterWith, issues);
    }

    if (filterWithout) {
      issues = utils.filterIssuesWithoutLabel(filterWithout, issues);
    }

    if (withAssigneesOnly) {
      issues = utils.filterAnyAssigneeIssues(issues);
    }

    stats.allIssues = issues;

    stats.thisMonth.allIssues = issues.filter(issue => date.isSame(issue.closedAt, 'month'));
    stats.thisMonth.stats = this.getIssuesStats(stats.thisMonth.allIssues);

    stats.oneMonthAgo.allIssues = issues.filter((issue) => {
      const closedAt = moment(issue.closedAt).add(1, 'month');
      return date.isSame(closedAt, 'month');
    });
    stats.oneMonthAgo.stats = this.getIssuesStats(stats.oneMonthAgo.allIssues);

    stats.twoMonthsAgo.allIssues = issues.filter((issue) => {
      const closedAt = moment(issue.closedAt).add(2, 'month');
      return date.isSame(closedAt, 'month');
    });
    stats.twoMonthsAgo.stats = this.getIssuesStats(stats.twoMonthsAgo.allIssues, true);

    stats.threeMonthsAgo.allIssues = issues.filter((issue) => {
      const closedAt = moment(issue.closedAt).add(3, 'month');
      return date.isSame(closedAt, 'month');
    });
    stats.threeMonthsAgo.stats = this.getIssuesStats(stats.threeMonthsAgo.allIssues, true);

    return stats;
  }

  getIssuesUsers = (issues) => {
    let users = [];

    issues.forEach((issue) => {
      users = users.concat(issue.assignees.nodes);
    });

    return uniqBy(users, 'login').map(user => cloneDeep(user));
  }

  getUserStats = (user, issues, filterWith, filterWithout) => {
    const node = cloneDeep(user);
    const assignedIssues = utils.filterAssignedIssues(node.login, issues);

    node.stats = this.getRepoStats(assignedIssues, filterWith, filterWithout);

    return node;
  }

  renderStats = (stats, user) => {
    const {
      thisMonth, oneMonthAgo, twoMonthsAgo, threeMonthsAgo
    } = stats;
    const { openInfo, showLess } = this.state;

    if (user && user.login) {
      thisMonth.stats.timeToAssign = thisMonth.stats.timeToAssign[user.login];
      oneMonthAgo.stats.timeToAssign = oneMonthAgo.stats.timeToAssign[user.login];
      twoMonthsAgo.stats.timeToAssign = twoMonthsAgo.stats.timeToAssign[user.login];
      threeMonthsAgo.stats.timeToAssign = threeMonthsAgo.stats.timeToAssign[user.login];

      if (thisMonth.stats.timeToCode > thisMonth.stats.timeToAssign) thisMonth.stats.timeToCode -= thisMonth.stats.timeToAssign;
      else thisMonth.stats.timeToAssign = 0;
      if (oneMonthAgo.stats.timeToCode > oneMonthAgo.stats.timeToAssign) oneMonthAgo.stats.timeToCode -= oneMonthAgo.stats.timeToAssign;
      else oneMonthAgo.stats.timeToAssign = 0;
      if (twoMonthsAgo.stats.timeToCode > twoMonthsAgo.stats.timeToAssign) twoMonthsAgo.stats.timeToCode -= twoMonthsAgo.stats.timeToAssign;
      else twoMonthsAgo.stats.timeToAssign = 0;
      if (threeMonthsAgo.stats.timeToCode > threeMonthsAgo.stats.timeToAssign) threeMonthsAgo.stats.timeToCode -= threeMonthsAgo.stats.timeToAssign;
      else threeMonthsAgo.stats.timeToAssign = 0;
    } else {
      thisMonth.stats.timeToAssign = thisMonth.stats.timeToAssign.all;
      oneMonthAgo.stats.timeToAssign = oneMonthAgo.stats.timeToAssign.all;
      twoMonthsAgo.stats.timeToAssign = twoMonthsAgo.stats.timeToAssign.all;
      threeMonthsAgo.stats.timeToAssign = threeMonthsAgo.stats.timeToAssign.all;

      if (thisMonth.stats.timeToCode > thisMonth.stats.timeToAssign) thisMonth.stats.timeToCode -= thisMonth.stats.timeToAssign;
      else thisMonth.stats.timeToAssign = 0;
      if (oneMonthAgo.stats.timeToCode > oneMonthAgo.stats.timeToAssign) oneMonthAgo.stats.timeToCode -= oneMonthAgo.stats.timeToAssign;
      else oneMonthAgo.stats.timeToAssign = 0;
      if (twoMonthsAgo.stats.timeToCode > twoMonthsAgo.stats.timeToAssign) twoMonthsAgo.stats.timeToCode -= twoMonthsAgo.stats.timeToAssign;
      else twoMonthsAgo.stats.timeToAssign = 0;
      if (threeMonthsAgo.stats.timeToCode > threeMonthsAgo.stats.timeToAssign) threeMonthsAgo.stats.timeToCode -= threeMonthsAgo.stats.timeToAssign;
      else threeMonthsAgo.stats.timeToAssign = 0;
    }

    return (
      <Segment key={user && user.login} secondary={!user}>
        {user && <Avatar src={user.avatarUrl} name={user.login} />}
        {!user && (
          <Checkbox
            checked={openInfo}
            onChange={() => this.setState({ openInfo: !openInfo })}
            label="Show info"
            style={{ padding: '0em 1em' }}
          />
        )}
        {
          (user || openInfo)
          && [
            <div key="info" style={{ display: 'flex', paddingTop: '1em' }}>
              <div style={{ width: '30%', padding: '0em 1em' }}>
                <span style={{ ...styles.title }}>
                  {moment().subtract(3, 'month').format('MMMM')}
                  {' '}
                  closed issues
                </span>
                <Divider />
                <div>
                  <span style={{ fontWeight: 'bold' }}>Total</span>
                  :
                  {' '}
                  {threeMonthsAgo.allIssues.length}
                </div>
              </div>
              <div style={{ width: '30%', padding: '0em 1em' }}>
                <span style={{ ...styles.title }}>
                  {moment().subtract(2, 'month').format('MMMM')}
                  {' '}
                  closed issues
                </span>
                <Divider />
                <div>
                  <span style={{ fontWeight: 'bold' }}>Total</span>
                  :
                  {' '}
                  {twoMonthsAgo.allIssues.length}
                </div>
              </div>
              <div style={{ width: '30%', padding: '0em 1em' }}>
                <span style={{ ...styles.title }}>
                  {moment().subtract(1, 'month').format('MMMM')}
                  {' '}
                  closed issues
                </span>
                <Divider />
                <div>
                  <span style={{ fontWeight: 'bold' }}>Total</span>
                  :
                  {' '}
                  {oneMonthAgo.allIssues.length}
                </div>
              </div>
              <div style={{ width: '30%', padding: '0em 1em' }}>
                <span style={{ ...styles.title }}>
                  {moment().format('MMMM')}
                  {' '}
                  closed issues
                </span>
                <Divider />
                <div>
                  <span style={{ fontWeight: 'bold' }}>Total</span>
                  :
                  {' '}
                  {thisMonth.allIssues.length}
                </div>
              </div>
            </div>,
            !showLess || !user
              ? (
                <div key="graph" style={{ paddingTop: '1em' }}>
                  <Chart
                    width="100%"
                    height="220px"
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ['Month', 'Time to Assign', 'Time to Code', 'Time to Review', 'Time to Beta', 'Time to Test', 'Time to Launch'],
                      [moment().format('MMMM') + ` (${round(sum(Object.values(thisMonth.stats).map(count => count || 0)))})`, thisMonth.stats.timeToAssign || 0, thisMonth.stats.timeToCode || 0, thisMonth.stats.timeToReview || 0, thisMonth.stats.timeToBeta || 0, thisMonth.stats.timeToTest || 0, thisMonth.stats.timeToLaunch || 0],
                      [moment().subtract(1, 'month').format('MMMM') + ` (${round(sum(Object.values(oneMonthAgo.stats).map(count => count || 0)))})`, oneMonthAgo.stats.timeToAssign || 0, oneMonthAgo.stats.timeToCode || 0, oneMonthAgo.stats.timeToReview || 0, oneMonthAgo.stats.timeToBeta || 0, oneMonthAgo.stats.timeToTest || 0, oneMonthAgo.stats.timeToLaunch || 0],
                      [moment().subtract(2, 'month').format('MMMM') + ` (${round(sum(Object.values(twoMonthsAgo.stats).map(count => count || 0)))})`, twoMonthsAgo.stats.timeToAssign || 0, twoMonthsAgo.stats.timeToCode || 0, twoMonthsAgo.stats.timeToReview || 0, twoMonthsAgo.stats.timeToBeta || 0, twoMonthsAgo.stats.timeToTest || 0, twoMonthsAgo.stats.timeToLaunch || 0],
                      [moment().subtract(3, 'month').format('MMMM') + ` (${round(sum(Object.values(threeMonthsAgo.stats).map(count => count || 0)))})`, threeMonthsAgo.stats.timeToAssign || 0, threeMonthsAgo.stats.timeToCode || 0, threeMonthsAgo.stats.timeToReview || 0, threeMonthsAgo.stats.timeToBeta || 0, threeMonthsAgo.stats.timeToTest || 0, threeMonthsAgo.stats.timeToLaunch || 0]
                    ]}
                    options={{
                      chartArea: { width: '60%' },
                      isStacked: true,
                      hAxis: {
                        title: 'Total Time from Open to Launch',
                        minValue: 0,
                      }
                    }}
                  />
                </div>
              )
              : null
          ]
        }
      </Segment>
    );
  }

  renderLabel = label => ({
    style: label.color,
    content: `${label.text}`
  })

  render() {
    const {
      issues,
      showLess
    } = this.state;

    if (issues === null) {
      return (
        <div id="RepositoryUsers">
          Loading...
        </div>
      );
    }

    const {
      users, repoStats, labels, selectedTypes, selectedPriorities, withLabels, withoutLabels
    } = this.state;

    let filteredUsers = users;
    let filteredRepoStats = repoStats;

    if (selectedTypes.length) {
      filteredUsers = filteredUsers.map(user => this.getUserStats(user, user.stats.allIssues, selectedTypes));
      filteredRepoStats = this.getRepoStats(filteredRepoStats.allIssues, selectedTypes, null, true);
    }

    if (selectedPriorities.length) {
      filteredUsers = filteredUsers.map(user => this.getUserStats(user, user.stats.allIssues, selectedPriorities));
      filteredRepoStats = this.getRepoStats(filteredRepoStats.allIssues, selectedPriorities, null, true);
    }

    if (withLabels.length) {
      filteredUsers = filteredUsers.map(user => this.getUserStats(user, user.stats.allIssues, withLabels));
      filteredRepoStats = this.getRepoStats(filteredRepoStats.allIssues, withLabels, null, true);
    }

    if (withoutLabels.length) {
      filteredUsers = filteredUsers.map(user => this.getUserStats(user, user.stats.allIssues, null, withoutLabels));
      filteredRepoStats = this.getRepoStats(filteredRepoStats.allIssues, null, withoutLabels, true);
    }

    return (
      <div id="RepositoryUsers">
        <div style={{ display: 'flex' }}>
          <Dropdown
            placeholder="Filter type"
            multiple
            selection
            value={selectedTypes}
            options={typeOptions()}
            onChange={(e, data) => this.setState({ selectedTypes: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Filter priority"
            multiple
            selection
            value={selectedPriorities}
            options={priorityOptions()}
            onChange={(e, data) => this.setState({ selectedPriorities: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="With labels"
            multiple
            selection
            value={withLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withLabels: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Without labels"
            multiple
            selection
            value={withoutLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withoutLabels: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Button
            content={showLess ? 'Show more' : 'Show less'}
            onClick={() => this.setState({ showLess: !showLess })}
          />
        </div>
        {this.renderStats(filteredRepoStats)}
        <div>
          {filteredUsers.map(user => this.renderStats(user.stats, user))}
        </div>
      </div>
    );
  }
}

export default Users;
