
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import { List, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import '../../assets/App.css';

class RepositoryMilestones extends Component {
  render() {
    const { data, match } = this.props;

    if ((data.loading && !data.repository) || !data.repository) {
      return (
        <div id="RepositoryMilestones">
          Loading...
        </div>
      );
    }

    const { milestones } = data.repository;
    const { repository_name, organization_login } = match.params;

    return (
      <div id="RepositoryMilestones">
        <List selection verticalAlign="middle">
          {milestones.nodes.map(m => (
            <List.Item key={m.number} as={Link} to={`/organizations/${organization_login}/repositories/${repository_name}/milestones/${m.number}`}>
              <List.Content>
                <List.Header>
                  <Icon name="arrow right" />
                  {m.title}
                  {' ∙ '}
                  <span style={{ color: m.closed ? 'red' : 'green', fontWeight: 'normal' }}>{m.closed ? 'closed' : 'open'}</span>
                </List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const RepositoryMilestonesQuery = gql`query RepositoryNodeQuery($name: String!, $owner: String!) {
  repository(name: $name, owner: $owner) {
    milestones (first: 20, orderBy: { field: UPDATED_AT, direction: DESC }) {
      nodes {
        title
        number
        closed
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const RepositoryMilestonesWithData = graphql(RepositoryMilestonesQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login
    }
  })
})(observer(RepositoryMilestones));

export default inject('store')(RepositoryMilestonesWithData);
