import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import { Divider, Container } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';

import RepositoryMilestones from './Milestones';
import RepositoryIssues from './Issues';
import RepositoryDashboard from './Dashboard';
import RepositoryUsers from './Users';
import RepositoryHeader from './Header';
import MilestoneNode from '../Milestone/Node';

import '../../assets/App.css';

class RepositoryNode extends Component {
  componentWillReceiveProps(nextProps) {
    const { data, store } = nextProps;

    if ((data.loading && !data.repository) || !data.repository) return;

    store.currentRepository = data.repository;
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.currentRepository = null;
  }

  render() {
    const { store } = this.props;

    if (!store.currentRepository) {
      return (
        <div id="RepositoryNode">
          Loading...
        </div>
      );
    }

    return (
      <div id="RepositoryNode">
        <RepositoryHeader {...this.props} />
        <Divider />
        <Container>
          <Switch>
            <Route path="/organizations/:organization_login/repositories/:repository_name/milestones/:milestone_number" component={MilestoneNode} />
            <Route path="/organizations/:organization_login/repositories/:repository_name/milestones" component={RepositoryMilestones} />
            <Route path="/organizations/:organization_login/repositories/:repository_name/dashboard" component={RepositoryDashboard} />
            <Route path="/organizations/:organization_login/repositories/:repository_name/users" component={RepositoryUsers} />
            <Route component={RepositoryIssues} />
          </Switch>
        </Container>
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const RepositoryNodeQuery = gql`query RepositoryNodeQuery($name: String!, $owner: String!) {
  repository(name: $name, owner: $owner) {
    name
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const RepositoryNodeWithData = graphql(RepositoryNodeQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login
    }
  })
})(observer(RepositoryNode));

export default inject('store')(RepositoryNodeWithData);
