import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import { List, Icon, Checkbox } from 'semantic-ui-react';
import { Route, Link, Switch } from 'react-router-dom';

import RepositoryNode from '../Repository/Node';
import OrganizationHeader from './Header';
import OrganizationDashboard from './Dashboard';
import OrganizationUsers from './Users';

import '../../assets/App.css';

class OrganizationNode extends Component {
  state = {
    selectedRepositories: []
  }

  componentWillReceiveProps(nextProps) {
    const { data, store } = nextProps;

    if ((data.loading && !data.organization) || !data.organization) return;

    store.currentOrganization = data.organization;
  }

  onChangeSelectedRepository = (name) => {
    const { selectedRepositories } = this.state;
    const index = selectedRepositories.indexOf(name);

    if (index > -1) {
      selectedRepositories.splice(index, 1);
    } else {
      selectedRepositories.push(name);
    }

    this.setState({ selectedRepositories });
  }

  render() {
    const { store } = this.props;

    if (!store.currentOrganization) {
      return (
        <div id="OrganizationNode">
          Loading...
        </div>
      );
    }

    const { login, repositories } = store.currentOrganization;
    const { selectedRepositories } = this.state;

    return (
      <div id="OrganizationNode">
        <Switch>
          <Route path="/organizations/:organization_login/repositories/:repository_name" component={RepositoryNode} />
          <Route path="/organizations/:organization_login/dashboard" component={OrganizationDashboard} />
          <Route path="/organizations/:organization_login/users" component={OrganizationUsers} />
          <Route
            path="/organizations/:organization_login"
            component={() => (
              <div>
                <OrganizationHeader {...this.props} {...this.state} style={selectedRepositories.length ? {} : { visibility: 'hidden' }} />
                <List selection verticalAlign="middle">
                  {repositories.nodes.map(repo => (
                    <List.Item key={repo.id}>
                      <List.Content floated="right">
                        <Checkbox
                          checked={!!selectedRepositories.find(r => r === repo.name)}
                          onChange={() => this.onChangeSelectedRepository(repo.name)}
                        />
                      </List.Content>
                      <List.Content as={Link} to={`/organizations/${login}/repositories/${repo.name}`}>
                        <List.Header>
                          <Icon name="arrow right" />
                          {' '}
                          {repo.name}
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const OrganizationNodeQuery = gql`query OrganizationNodeQuery($login: String!) {
  organization (login: $login) {
    name
    avatarUrl
    login
    description
    repositories (first: 20, orderBy: { field: UPDATED_AT, direction: DESC  }) {
      nodes {
        name
        id
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const OrganizationNodeWithData = graphql(OrganizationNodeQuery, {
  options: ownProps => ({
    variables: {
      login: ownProps.match.params.organization_login
    }
  })
})(observer(OrganizationNode));

export default inject('store')(OrganizationNodeWithData);
