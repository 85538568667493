import React, { Component } from 'react';
import { Header, Image } from 'semantic-ui-react';

import '../assets/App.css';

export default class Avatar extends Component {
  render() {
    const { src, name } = this.props;
    return (
      <Header as="h4">
        <Image circular src={src} />
        <Header.Content>
          {name}
        </Header.Content>
      </Header>
    );
  }
}
