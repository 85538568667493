/* eslint-disable no-await-in-loop */

import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import moment from 'moment';

import Dashboard from '../../components/Dashboard';

import '../../assets/App.css';

class RepositoryDashboard extends Dashboard {
  async componentWillReceiveProps(nextProps) {
    const { data, client, match } = nextProps;

    if ((data.loading && !data.repository) || !data.repository || this.state.issues || this.state.openIssues || this.state.closedIssues) return;

    let issues = data.repository.issues.nodes;
    let { pageInfo } = data.repository.issues;

    let openIssues = data.repository.open.nodes;
    let { pageInfo: pageInfoOpen } = data.repository.open;

    let closedIssues = data.repository.closed.nodes;
    let { pageInfo: pageInfoClosed } = data.repository.closed;

    while (pageInfo.hasNextPage && pageInfoOpen.hasNextPage && pageInfoClosed.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String, $afterOpen: String, $afterClosed: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, states: OPEN, after: $after) {
              nodes {
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
            open: issues (first: 100, after: $afterOpen, filterBy: { since: $since }) {
              nodes {
                createdAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
            closed: issues (first: 100, after: $afterClosed, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                closedAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfo.endCursor,
          afterOpen: pageInfoOpen.endCursor,
          afterClosed: pageInfoClosed.endCursor,
          since: moment().subtract(14, 'month').date(1).format()
        }
      });

      ({ pageInfo } = response.data.repository.issues);
      issues = issues.concat(response.data.repository.issues.nodes);

      pageInfoOpen = response.data.repository.open.pageInfo;
      openIssues = openIssues.concat(response.data.repository.open.nodes);

      pageInfoClosed = response.data.repository.closed.pageInfo;
      closedIssues = closedIssues.concat(response.data.repository.closed.nodes);
    }

    while (pageInfo.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, states: OPEN, after: $after) {
              nodes {
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfo.endCursor
        }
      });

      ({ pageInfo } = response.data.repository.issues.pageInfo);
      issues = issues.concat(response.data.repository.issues.nodes);
    }

    while (pageInfoOpen.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            open: issues (first: 100, after: $after, filterBy: { since: $since }) {
              nodes {
                createdAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfoOpen.endCursor,
          since: moment().subtract(14, 'month').date(1).format()
        }
      });

      pageInfoOpen = response.data.repository.open.pageInfo;
      openIssues = openIssues.concat(response.data.repository.open.nodes);
    }

    while (pageInfoClosed.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            closed: issues (first: 100, after: $after, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                closedAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfoClosed.endCursor,
          since: moment().subtract(14, 'month').date(1).format()
        }
      });

      pageInfoClosed = response.data.repository.closed.pageInfo;
      closedIssues = closedIssues.concat(response.data.repository.closed.nodes);
    }

    const openStats = this.getStats(openIssues);
    const closedStats = this.getStats(closedIssues);

    const labels = data.repository.labels.nodes
      .filter(label => !['priority: high', 'priority: normal', 'priority: low', 'bug', 'enhancement', 'feature'].includes(label.name))
      .map(label => ({
        key: label.name,
        text: label.name,
        value: label.name,
        color: {
          color: 'white',
          backgroundColor: '#' + label.color
        }
      }));

    this.setState({
      issues, openIssues, openStats, closedIssues, closedStats, labels
    });
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const RepositoryDashboardQuery = gql`query RepositoryNodeQuery($name: String!, $owner: String!, $since: DateTime) {
  repository(name: $name, owner: $owner) {
    labels (first: 40) {
      nodes {
        name
        color
      }
    }
    issues (first: 100, states: OPEN) {
      nodes {
        labels (first: 10) {
          nodes {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    open: issues (first: 100, filterBy: { since: $since }) {
      nodes {
        createdAt
        labels (first: 10) {
          nodes {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    closed: issues (first: 100, states: CLOSED, filterBy: { since: $since }) {
      nodes {
        closedAt
        labels (first: 10) {
          nodes {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const RepositoryDashboardWithData = graphql(RepositoryDashboardQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login,
      since: moment().subtract(14, 'month').date(1).format()
    }
  })
})(observer(RepositoryDashboard));

export default inject('store', 'client')(RepositoryDashboardWithData);
