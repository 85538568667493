import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal, Button, Icon, List, Table
} from 'semantic-ui-react';
import moment from 'moment';
import { flatten, chunk, uniq } from 'lodash';

import IssueItem from '../Issue/Item';

import '../../assets/App.css';

class MilestoneZendesk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      issues: [],
      jobs: [],
      loaded: false,
      loading: false,
      updated: false
    };
  }

  getZendeskTickets = async () => {
    this.setState({ loading: true });

    const {
      api,
      issues
    } = this.props;
    const issuesWithTickets = [];

    const promises = issues.map(async (issue) => {
      try {
        const res = await api.get(`search.json?query="${issue.url.replace('https://github.com/classapp', '')}"`);

        issue.tickets = res.results;

        issuesWithTickets.push(issue);
      } catch (e) {
        console.log(e);
      }
    });

    await Promise.all(promises);

    this.setState({ loaded: true, loading: false, issues: issuesWithTickets });
  }

  renderGetTickets = () => (
    <div>
      <span style={{ marginBottom: 32 }}>
      Hello!! Now we are going to get all zendesk tickets associated with the issues from this milestone
      </span>

      <List selection verticalAlign="middle" divided>
        {this.props.issues.map(issue => <IssueItem key={issue.id} {...issue} simple />)}
      </List>
    </div>
  )

  updateZendeskTickets = async () => {
    this.setState({ loading: true });

    const {
      api,
    } = this.props;
    const {
      issues
    } = this.state;
    const tickets = chunk(uniq(flatten(issues.map(issue => issue.tickets.map(ticket => ticket.id)))), 100);
    const jobs = [];

    const promises = tickets.map(async (ticket) => {
      try {
        const res = await api.put(`tickets/update_many.json?ids=${ticket.join(',')}`, { ticket: { additional_tags: ['corrigido'] } });

        jobs.push(res.job_status);
      } catch (e) {
        console.log(e);
      }
    });

    await Promise.all(promises);

    this.setState({ updated: true, loading: false, jobs });
  }

  renderTickets = () => (
    <div>
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2">Issue ID</Table.HeaderCell>
            <Table.HeaderCell colSpan="4">Ticket</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            this.state.issues.filter(issue => !!issue.tickets.length).map(issue => (
              [
                <Table.Row key={issue.tickets[0].id}>
                  <Table.Cell rowSpan={issue.tickets.length}>
                    {`${issue.number} - ${issue.title.substring(0, 2000)}`}
                  </Table.Cell>
                  <Table.Cell>{issue.tickets[0].id}</Table.Cell>
                  <Table.Cell>{issue.tickets[0].status}</Table.Cell>
                  <Table.Cell>{moment(issue.tickets[0].created_at).format('llll')}</Table.Cell>
                  <Table.Cell>
                    <a href={issue.tickets[0].url} target="_blank" rel="noreferrer noopener">
                      <Icon name="external" />
                    </a>
                  </Table.Cell>
                </Table.Row>,
                issue.tickets.length === 1 ? null
                  : issue.tickets.slice(1, issue.tickets.length).map(ticket => (
                    <Table.Row key={ticket.id}>
                      <Table.Cell>{ticket.id}</Table.Cell>
                      <Table.Cell>{ticket.status}</Table.Cell>
                      <Table.Cell>{moment(ticket.created_at).format('llll')}</Table.Cell>
                      <Table.Cell>
                        <a href={ticket.url} target="_blank" rel="noreferrer noopener">
                          <Icon name="external" />
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
              ]
            ))
          }
        </Table.Body>
      </Table>
    </div>
  )

  onClose = () => {
    this.props.onClose();
  }

  renderResult = () => (
    <div>

      <span style={{ marginBottom: 32 }}>
      Tickets succesfully updated. Click on job link bellow for detailed information:
      </span>

      <List selection verticalAlign="middle" divided>
        {this.state.jobs.map(job => (
          <List.Item key={job.id} as="a" href={job.url} target="_blank" rel="noreferrer noopener">
            <List.Content>
              Zendesk Job ID:
              {' '}
              {job.id}
            </List.Content>
          </List.Item>
        ))}
      </List>
    </div>
  )

  render() {
    const {
      loaded,
      updated,
      loading
    } = this.state;

    let onSubmit = this.onClose;
    let content = this.renderResult();

    if (!updated && loaded) {
      onSubmit = this.updateZendeskTickets;
      content = this.renderTickets();
    } else if (!loaded) {
      onSubmit = this.getZendeskTickets;
      content = this.renderGetTickets();
    }

    return (
      <Modal id="MilestoneZendesk" open onClose={this.onClose} closeIcon closeOnDimmerClick={false}>
        <Modal.Header>
          Zendesk Tickets
        </Modal.Header>
        <Modal.Content scrolling>
          {content}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={onSubmit} loading={loading} disabled={loading}>
            Proceed
            {' '}
            <Icon name="chevron right" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject('store', 'api')(observer(MilestoneZendesk));
