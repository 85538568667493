import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { CookiesProvider } from 'react-cookie';
import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';

import App from './containers/App';
import GraphQLNetworkInterface from './components/GraphQLNetworkInterface';

import * as serviceWorker from './serviceWorker';
import createStore from './lib/store';
import { createAPI } from './lib/api';

import './index.css';

const store = createStore();
const api = createAPI({ access_token: store.access_token });

const networkInterface = new GraphQLNetworkInterface('https://api.github.com/graphql');
networkInterface.use([{
  applyMiddleware(req, next) {
    if (!req.options.headers) req.options.headers = {};
    req.options.headers.authorization = store.token
      ? `Bearer ${store.token}` : null;
    next();
  }
}]);

const client = new ApolloClient({ networkInterface });

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store} api={api} client={client} networkInterface={networkInterface}>
      <ApolloProvider client={client} networkInterface={networkInterface}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
