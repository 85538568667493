/* eslint-disable no-await-in-loop */

import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import moment from 'moment';
import qs from 'qs';
import { uniqBy } from 'lodash';

import Users from '../../components/Users';

class OrganizationUsers extends Users {
  async componentWillReceiveProps(nextProps) {
    const { data, location } = nextProps;

    if ((data.loading && !data.repository) || !data.repository || this.state.issues) return;

    let issues = [];
    let labels = data.repository.labels.nodes;

    const { repos } = qs.parse(location.search.replace('?', ''));

    await Promise.all(repos.map(async (repo, i) => {
      if (!i) return;

      const response = await this.getRepoInfo(repo);

      labels = labels.concat(response.labels.nodes);
    }));


    await Promise.all(repos.map(async (repo) => {
      const response = await this.getRepoIssues(repo);

      issues = issues.concat(response.issues);
    }));

    issues = issues.map(issue => this.getIssueInfo(issue));

    const repoStats = this.getRepoStats(issues);
    let users = this.getIssuesUsers(issues);

    users = users.map(user => this.getUserStats(user, issues)).filter(user => user.stats.thisMonth.allIssues.length || user.stats.oneMonthAgo.allIssues.length || user.stats.twoMonthsAgo.allIssues.length);

    labels = uniqBy(labels, 'name').filter(label => !['priority: high', 'priority: normal', 'priority: low', 'bug', 'enhancement', 'feature'].includes(label.name))
      .map(label => ({
        key: label.name,
        text: label.name,
        value: label.name,
        color: {
          color: 'white',
          backgroundColor: '#' + label.color
        }
      }));

    this.setState({
      repoStats, issues, users, labels
    });
  }

  getRepoInfo = async (repo) => {
    const { client, match } = this.props;

    const res = await client.query({
      query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!) {
        repository(name: $name, owner: $owner) {
          labels (first: 40) {
            nodes {
              name
              color
            }
          }
        }
      }`,
      variables: {
        name: repo,
        owner: match.params.organization_login
      }
    });

    return res.data.repository;
  }

  getRepoIssues = async (repo) => {
    const { client, match } = this.props;

    const res = await client.query({
      query: gql`query OrganizationUsersQuery($name: String!, $owner: String!, $since: DateTime) {
        repository(name: $name, owner: $owner) {
          issues (first: 100, states: CLOSED, filterBy: { since: $since }) {
            nodes {
              number
              createdAt
              closedAt
              assignees (first: 10) {
                nodes {
                  login
                  avatarUrl
                }
              }
              timelineItems(first: 40, itemTypes: LABELED_EVENT) {
                nodes {
                  ... on LabeledEvent {
                    label {
                      name
                    }
                    createdAt
                  }
                }
              }
              assignedEvent: timelineItems(first: 40, itemTypes: ASSIGNED_EVENT) {
                nodes {
                  ... on AssignedEvent {
                    assignee {
                      ... on User {
                        login
                      }
                    }
                    createdAt
                  }
                }
              }
              labels (first: 10) {
                nodes {
                  name
                  color
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }`,
      variables: {
        name: repo,
        owner: match.params.organization_login,
        since: moment().subtract(3, 'month').date(1).format()
      }
    });

    let issues = res.data.repository.issues.nodes;
    let { pageInfo } = res.data.repository.issues;

    while (pageInfo.hasNextPage) {
      // Wait some time so github api won't deny the requests
      await new Promise(r => setTimeout(r, 200));

      try {
        const response = await client.query({
          query: gql`query OrganizationUsersQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, after: $after, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                number
                createdAt
                closedAt
                assignees (first: 10) {
                  nodes {
                    login
                    avatarUrl
                  }
                }
                timelineItems(first: 40, itemTypes: LABELED_EVENT) {
                  nodes {
                    ... on LabeledEvent {
                      label {
                        name
                      }
                      createdAt
                    }
                  }
                }
                assignedEvent: timelineItems(first: 40, itemTypes: ASSIGNED_EVENT) {
                  nodes {
                    ... on AssignedEvent {
                      assignee {
                        ... on User {
                          login
                        }
                      }
                      createdAt
                    }
                  }
                }
                labels (first: 10) {
                  nodes {
                    name
                    color
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
          variables: {
            name: repo,
            owner: match.params.organization_login,
            after: pageInfo.endCursor,
            since: moment().subtract(3, 'month').date(1).format()
          }
        });

        ({ pageInfo } = response.data.repository.issues);
        issues = issues.concat(response.data.repository.issues.nodes);
      } catch (e) {
        console.log(e);

        // Wait some time so github api won't deny the requests
        await new Promise(r => setTimeout(r, 10000));
      }
    }

    return { issues };
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const OrganizationUsersQuery = gql`query OrganizationUsersQuery($name: String!, $owner: String!) {
  repository(name: $name, owner: $owner) {
    labels (first: 40) {
      nodes {
        name
        color
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const OrganizationUsersWithData = graphql(OrganizationUsersQuery, {
  options: ownProps => ({
    variables: {
      name: qs.parse(ownProps.location.search.replace('?', '')).repos[0],
      owner: ownProps.match.params.organization_login
    }
  })
})(observer(OrganizationUsers));

export default inject('store', 'client')(OrganizationUsersWithData);
