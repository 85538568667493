import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import {
  Header, Segment, Dropdown, List, Grid, Button
} from 'semantic-ui-react';
import { uniqBy, cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import IssueItem from '../Issue/Item';
import Zendesk from './Zendesk';

import utils from '../../lib/utils';

import '../../assets/App.css';

const priorityOptions = () => ([
  {
    key: 'high', text: 'High', value: 'priority: high', color: { backgroundColor: '#b60205', color: 'white' }
  },
  {
    key: 'normal', text: 'Normal', value: 'priority: normal', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'low', text: 'Low', value: 'priority: low', color: { backgroundColor: '#f9d0c4', color: 'black' }
  },
]);

class MilestoneNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPriorities: [],
      milestoneStats: null,
      users: null,
      showZendesk: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { milestoneStats, users } = this.state;

    if ((data.loading && !data.repository) || !data.repository || (milestoneStats && users)) return;

    const { milestone } = data.repository;
    let { issues } = milestone;

    issues = issues.nodes.map(issue => utils.getIssueSLA(issue));

    this.setState({
      milestoneStats: this.getMilestoneStats(issues),
      users: this.getMilestoneUsers(issues).map(user => this.getUserStats(user, issues))
    });
  }

  getMilestoneUsers = (issues) => {
    let users = [];

    issues.forEach((issue) => {
      users = users.concat(issue.assignees.nodes);
    });

    return uniqBy(users, 'login').map(user => cloneDeep(user));
  }

  getMilestoneStats = (issues, filter) => {
    const stats = {};

    if (filter) {
      issues = utils.filterIssuesWithLabel(filter, issues);
    }

    stats.allIssues = issues;
    stats.codedIssues = utils.filterIssuesWithLabel(['coded'], issues);
    stats.passedIssues = utils.filterIssuesWithLabel(['passed'], issues);
    stats.reviewIssues = utils.filterIssuesWithLabel(['review'], issues);
    stats.betaIssues = utils.filterIssuesWithLabel(['beta'], issues);

    return stats;
  }

  getUserStats = (user, issues, filter) => {
    const node = cloneDeep(user);
    const assignedIssues = utils.filterAssignedIssues(node.login, issues);

    node.stats = this.getMilestoneStats(assignedIssues, filter);

    return node;
  }

  renderMilestoneStats = stats => (
    <Segment secondary>
      <div>
        <span style={{ fontWeight: 'bold' }}>Total</span>
        :
        {' '}
        {stats.allIssues.length}
      </div>
      <div>
        <span style={{ color: '#0e8a16', fontWeight: 'bold' }}>Coded</span>
        :
        {' '}
        {stats.codedIssues.length}
      </div>
      <div>
        <span style={{ color: '#0052cc', fontWeight: 'bold' }}>Passed</span>
        :
        {' '}
        {stats.passedIssues.length}
      </div>
      <div>
        <span style={{ color: '#fbca04', fontWeight: 'bold' }}>Review</span>
        :
        {' '}
        {stats.reviewIssues.length}
      </div>
      <div>
        <span style={{ color: '#95f490', fontWeight: 'bold' }}>Beta</span>
        :
        {' '}
        {stats.betaIssues.length}
      </div>
    </Segment>
  )

  renderUserStats = user => (
    <Grid.Column
      key={user.login}
      as={Segment}
      style={{
        height: '200px',
        width: '250px',
        margin: '1rem'
      }}
    >
      <Avatar src={user.avatarUrl} name={user.login} />
      <div>
        <span style={{ fontWeight: 'bold' }}>Total</span>
        :
        {' '}
        {user.stats.allIssues.length}
      </div>
      <div>
        <span style={{ color: '#0e8a16', fontWeight: 'bold' }}>Coded</span>
        :
        {' '}
        {user.stats.codedIssues.length}
      </div>
      <div>
        <span style={{ color: '#0052cc', fontWeight: 'bold' }}>Passed</span>
        :
        {' '}
        {user.stats.passedIssues.length}
      </div>
      <div>
        <span style={{ color: '#fbca04', fontWeight: 'bold' }}>Review</span>
        :
        {' '}
        {user.stats.reviewIssues.length}
      </div>
      <div>
        <span style={{ color: '#95f490', fontWeight: 'bold' }}>Beta</span>
        :
        {' '}
        {user.stats.betaIssues.length}
      </div>
    </Grid.Column>
  )

  renderLabel = label => ({
    style: label.color,
    content: `${label.text}`
  })

  render() {
    const {
      milestoneStats, users, selectedPriorities, showZendesk
    } = this.state;

    if (!milestoneStats || !users) {
      return (
        <div id="MilestoneNode">
          Loading...
        </div>
      );
    }

    const { match, data } = this.props;
    let filteredMilestoneStats = milestoneStats;
    let filteredUsers = users;

    if (selectedPriorities.length) {
      filteredMilestoneStats = this.getMilestoneStats(milestoneStats.allIssues, selectedPriorities);
      filteredUsers = users.map(user => this.getUserStats(user, user.stats.allIssues, selectedPriorities));
    }

    const { repository_name, organization_login } = match.params;

    return (
      <div id="MilestoneNode">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Header as="h3">
            <Header.Content as={Link} to={`/organizations/${organization_login}/repositories/${repository_name}/milestones`}>
              {data.repository.milestone.title}
            </Header.Content>
          </Header>

          <div>
            <Dropdown
              placeholder="Filter priority"
              multiple
              selection
              value={selectedPriorities}
              options={priorityOptions()}
              onChange={(e, d) => this.setState({ selectedPriorities: d.value })}
              renderLabel={this.renderLabel}
            />
            <Button style={{ marginLeft: 24 }} color="blue" onClick={() => this.setState({ showZendesk: true })}>
              Zendesk
            </Button>
          </div>
        </div>
        {this.renderMilestoneStats(filteredMilestoneStats)}
        <Grid container>
          {filteredUsers.map(user => this.renderUserStats(user))}
        </Grid>
        <List selection verticalAlign="middle" divided>
          {filteredMilestoneStats.allIssues.map(issue => <IssueItem key={issue.id} {...issue} />)}
        </List>
        {showZendesk && <Zendesk issues={milestoneStats.allIssues.filter(issue => issue.closed)} onClose={() => this.setState({ showZendesk: false })} />}
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const MilestoneNodeQuery = gql`query MilestoneNodeQuery($name: String!, $owner: String!, $number: Int!) {
  repository(name: $name, owner: $owner) {
    milestone (number: $number) {
      title
      issues (first: 100) {
        nodes {
          assignees (first: 10) {
            nodes {
              login
              avatarUrl
            }
          }
          labels (first: 10) {
            nodes {
              name
            }
          }
          ... IssueItem
          closed
        }
      }
    }
  }
}
${IssueItem.fragments.issue}
`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const MilestoneNodeWithData = graphql(MilestoneNodeQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login,
      number: parseInt(ownProps.match.params.milestone_number, 10)
    }
  })
})(observer(MilestoneNode));

export default inject('store')(MilestoneNodeWithData);
