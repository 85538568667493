/* eslint-disable no-await-in-loop */

import React, { Component } from 'react';
import {
  Segment, Dropdown
} from 'semantic-ui-react';
import moment from 'moment';
import { Chart } from 'react-google-charts';

import utils from '../lib/utils';

const date = moment();

const typeOptions = () => ([
  {
    key: 'bug', text: 'Bug', value: 'bug', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'enhancement', text: 'Enhancement', value: 'enhancement', color: { backgroundColor: '#bfd4f2', color: 'black' }
  },
  {
    key: 'feature', text: 'Feature', value: 'feature', color: { backgroundColor: '#bfd4f2', color: 'black' }
  }
]);

const priorityOptions = () => ([
  {
    key: 'high', text: 'High', value: 'priority: high', color: { backgroundColor: '#b60205', color: 'white' }
  },
  {
    key: 'normal', text: 'Normal', value: 'priority: normal', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'low', text: 'Low', value: 'priority: low', color: { backgroundColor: '#f9d0c4', color: 'black' }
  },
]);

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTypes: ['bug'],
      selectedPriorities: [],
      withLabels: [],
      withoutLabels: ['hold', 'beta'],
      labels: [],
      issues: null,
      openIssues: null,
      openStats: {},
      closedIssues: null,
      closedStats: {}
    };
  }

  getStats = (issues, filterWith, filterWithout) => {
    const stats = {
      thisMonth: {},
      oneMonthAgo: {},
      twoMonthsAgo: {},
      threeMonthsAgo: {},
      fourMonthsAgo: {},
      fiveMonthsAgo: {},
      sixMonthsAgo: {},
      sevenMonthsAgo: {},
      eightMonthsAgo: {},
      nineMonthsAgo: {},
      tenMonthsAgo: {},
      elevenMonthsAgo: {},
      twelveMonthsAgo: {},
      thirteenMonthsAgo: {}
    };

    if (filterWith) {
      issues = utils.filterIssuesWithLabel(filterWith, issues);
    }

    if (filterWithout) {
      issues = utils.filterIssuesWithoutLabel(filterWithout, issues);
    }

    stats.allIssues = issues;

    stats.thisMonth.allIssues = issues.filter(issue => date.isSame(issue.closedAt || issue.createdAt, 'month'));

    stats.oneMonthAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(1, 'month');
      return date.isSame(d, 'month');
    });

    stats.twoMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(2, 'month');
      return date.isSame(d, 'month');
    });

    stats.threeMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(3, 'month');
      return date.isSame(d, 'month');
    });

    stats.fourMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(4, 'month');
      return date.isSame(d, 'month');
    });

    stats.fiveMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(5, 'month');
      return date.isSame(d, 'month');
    });

    stats.sixMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(6, 'month');
      return date.isSame(d, 'month');
    });

    stats.sevenMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(7, 'month');
      return date.isSame(d, 'month');
    });

    stats.eightMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(8, 'month');
      return date.isSame(d, 'month');
    });

    stats.nineMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(9, 'month');
      return date.isSame(d, 'month');
    });

    stats.tenMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(10, 'month');
      return date.isSame(d, 'month');
    });

    stats.elevenMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(11, 'month');
      return date.isSame(d, 'month');
    });

    stats.twelveMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(12, 'month');
      return date.isSame(d, 'month');
    });

    stats.thirteenMonthsAgo.allIssues = issues.filter((issue) => {
      const d = moment(issue.closedAt || issue.createdAt).add(13, 'month');
      return date.isSame(d, 'month');
    });

    return stats;
  }

  renderLabel = label => ({
    style: label.color,
    content: `${label.text}`
  })

  render() {
    const { issues } = this.state;

    if (issues === null) {
      return (
        <div id="Dashboard">
          Loading...
        </div>
      );
    }

    const {
      openStats, closedStats, labels, selectedTypes, selectedPriorities, withLabels, withoutLabels
    } = this.state;

    let filteredIssues = issues;
    let filteredOpenStats = openStats;
    let filteredClosedStats = closedStats;

    if (selectedTypes.length) {
      filteredIssues = utils.filterIssuesWithLabel(selectedTypes, filteredIssues);
      filteredOpenStats = this.getStats(filteredOpenStats.allIssues, selectedTypes);
      filteredClosedStats = this.getStats(filteredClosedStats.allIssues, selectedTypes);
    }

    if (selectedPriorities.length) {
      filteredIssues = utils.filterIssuesWithLabel(selectedPriorities, filteredIssues);
      filteredOpenStats = this.getStats(filteredOpenStats.allIssues, selectedPriorities);
      filteredClosedStats = this.getStats(filteredClosedStats.allIssues, selectedPriorities);
    }

    if (withLabels.length) {
      filteredIssues = utils.filterIssuesWithLabel(withLabels, filteredIssues);
      filteredOpenStats = this.getStats(filteredOpenStats.allIssues, withLabels);
      filteredClosedStats = this.getStats(filteredClosedStats.allIssues, withLabels);
    }

    if (withoutLabels.length) {
      filteredIssues = utils.filterIssuesWithoutLabel(withoutLabels, filteredIssues);
      filteredOpenStats = this.getStats(filteredOpenStats.allIssues, null, withoutLabels);
      filteredClosedStats = this.getStats(filteredClosedStats.allIssues, null, withoutLabels);
    }

    return (
      <div id="Dashboard">
        <div style={{ display: 'flex' }}>
          <Dropdown
            placeholder="Filter type"
            multiple
            selection
            value={selectedTypes}
            options={typeOptions()}
            onChange={(e, data) => this.setState({ selectedTypes: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Filter priority"
            multiple
            selection
            value={selectedPriorities}
            options={priorityOptions()}
            onChange={(e, data) => this.setState({ selectedPriorities: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="With labels"
            multiple
            selection
            value={withLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withLabels: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Without labels"
            multiple
            selection
            value={withoutLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withoutLabels: data.value })}
            renderLabel={this.renderLabel}
          />
        </div>
        <Segment secondary>
          <div>
            <span style={{ fontWeight: 'bold' }}>Open issues</span>
            :
            {' '}
            {filteredIssues.length}
          </div>
        </Segment>
        <div style={{ paddingTop: '1em' }}>
          <Chart
            width="100%"
            height="250px"
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Month', 'Closed', 'Open', 'Target'],
              [moment().subtract(13, 'month').format('MMM'), filteredClosedStats.thirteenMonthsAgo.allIssues.length, filteredOpenStats.thirteenMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.thirteenMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(12, 'month').format('MMM'), filteredClosedStats.twelveMonthsAgo.allIssues.length, filteredOpenStats.twelveMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.twelveMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(11, 'month').format('MMM'), filteredClosedStats.elevenMonthsAgo.allIssues.length, filteredOpenStats.elevenMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.elevenMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(10, 'month').format('MMM'), filteredClosedStats.tenMonthsAgo.allIssues.length, filteredOpenStats.tenMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.tenMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(9, 'month').format('MMM'), filteredClosedStats.nineMonthsAgo.allIssues.length, filteredOpenStats.nineMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.nineMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(8, 'month').format('MMM'), filteredClosedStats.eightMonthsAgo.allIssues.length, filteredOpenStats.eightMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.eightMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(7, 'month').format('MMM'), filteredClosedStats.sevenMonthsAgo.allIssues.length, filteredOpenStats.sevenMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.sevenMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(6, 'month').format('MMM'), filteredClosedStats.sixMonthsAgo.allIssues.length, filteredOpenStats.sixMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.sixMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(5, 'month').format('MMM'), filteredClosedStats.fiveMonthsAgo.allIssues.length, filteredOpenStats.fiveMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.fiveMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(4, 'month').format('MMM'), filteredClosedStats.fourMonthsAgo.allIssues.length, filteredOpenStats.fourMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.fourMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(3, 'month').format('MMM'), filteredClosedStats.threeMonthsAgo.allIssues.length, filteredOpenStats.threeMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.threeMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(2, 'month').format('MMM'), filteredClosedStats.twoMonthsAgo.allIssues.length, filteredOpenStats.twoMonthsAgo.allIssues.length, Math.ceil(filteredOpenStats.twoMonthsAgo.allIssues.length * 1.36)],
              [moment().subtract(1, 'month').format('MMM'), filteredClosedStats.oneMonthAgo.allIssues.length, filteredOpenStats.oneMonthAgo.allIssues.length, Math.ceil(filteredOpenStats.oneMonthAgo.allIssues.length * 1.36)],
              [moment().format('MMM'), filteredClosedStats.thisMonth.allIssues.length, filteredOpenStats.thisMonth.allIssues.length, Math.ceil(filteredOpenStats.thisMonth.allIssues.length * 1.36)],
            ]}
            options={{
              colors: ['#cb2431', '#2cbe4e'],
              hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
              vAxis: { minValue: 0 },
              seriesType: 'area',
              series: { 2: { type: 'line', color: 'blue', lineWidth: 1 } },
              // For the legend to fit, we make the chart area smaller
              chartArea: { width: '70%' },
            }}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
