import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import '../assets/App.css';

class NotFound extends Component {
  render() {
    return (
      <div className="NotFound">
        Error 404
      </div>
    );
  }
}

export default inject('store')(observer(NotFound));
