import axios from 'axios';

export const APIKey = 'e7880cd4d6bd06e34d52c023d8472ef6704d9989dd0b86152a65a85e3a6684e3';

export const createAPI = ({ access_token }) => {
  const api = {};

  api.url = 'https://classapp.zendesk.com/api/v2/';

  api.APIKey = APIKey;

  const setParams = (params = {}) => ({
    ...params
  });

  const setHeaders = (headers = {}) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${api.APIKey}`,
    ...headers
  });

  const get = (endpoint, params = {}, headers = {}) => {
    const finalParams = (params === false) ? null : setParams(params);
    const finalHeaders = (headers === false) ? null : setHeaders(headers);
    console.log('GET ' + api.url + endpoint, finalParams);

    return axios({
      method: 'get',
      url: `${api.url + endpoint}`,
      headers: finalHeaders,
      params: finalParams
    }).then(response => response.data).catch((error) => {
      console.log('get error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.get = get;

  const post = (endpoint, params = {}, headers = {}) => {
    const finalParams = (params === false) ? null : setParams(params);
    const finalHeaders = (headers === false) ? null : setHeaders(headers);

    console.log('POST ' + endpoint, finalParams);

    return axios({
      method: 'post',
      url: `${api.url + endpoint}`,
      headers: finalHeaders,
      data: finalParams
    }).then(response => response).catch((error) => {
      console.log('post error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.post = post;

  api.postAuthorized = (endpoint, params, headers) => post(endpoint, params, {
    ...headers,
    Authorization: api.access_token
  });

  const deleteRequest = (endpoint, params = {}, headers = {}) => { //for some reason can't simply call it delete
    const finalParams = (params === false) ? null : setParams(params);
    const finalHeaders = (headers === false) ? null : setHeaders(headers);

    console.log('DELETE ' + endpoint, finalParams);

    return axios({
      method: 'delete',
      url: `${api.url + endpoint}`,
      headers: finalHeaders,
      data: finalParams
    }).then(response => response).catch((error) => {
      console.log('delete error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.delete = deleteRequest;

  api.deleteAuthorized = (endpoint, params, headers) => post(endpoint, params, {
    ...headers,
    Authorization: api.access_token
  });

  const put = (endpoint, params = {}, headers = {}) => {
    const finalParams = (params === false) ? null : { params: setParams(params) };
    const finalHeaders = (headers === false) ? null : setHeaders(headers);

    console.log('PUT ' + endpoint, finalParams);
    return axios({
      method: 'put',
      url: `${api.url + endpoint}`,
      headers: finalHeaders,
      data: finalParams.params
    }).then(response => response.data).catch((error) => {
      console.log('put error', error);

      if (error.response) {
        throw error.response.data;
      }
    });
  };

  api.put = put;

  return api;
};
