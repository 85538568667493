/* eslint-disable no-await-in-loop */

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import {
  List, Segment, Divider, Dropdown, Checkbox
} from 'semantic-ui-react';

import utils from '../../lib/utils';

import IssueItem from '../Issue/Item';

import '../../assets/App.css';

const styles = {
  title: {
    color: 'black',
    fontWeight: 'bold'
  },
  coded: {
    color: '#0e8a16',
    fontWeight: 'bold'
  },
  passed: {
    color: '#0052cc',
    fontWeight: 'bold'
  },
  review: {
    color: '#fbca04',
    fontWeight: 'bold'
  },
  beta: {
    color: '#95f490',
    fontWeight: 'bold'
  }
};

const typeOptions = () => ([
  {
    key: 'bug', text: 'Bug', value: 'bug', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'enhancement', text: 'Enhancement', value: 'enhancement', color: { backgroundColor: '#bfd4f2', color: 'black' }
  },
  {
    key: 'feature', text: 'Feature', value: 'feature', color: { backgroundColor: '#bfd4f2', color: 'black' }
  }
]);

const priorityOptions = () => ([
  {
    key: 'high', text: 'High', value: 'priority: high', color: { backgroundColor: '#b60205', color: 'white' }
  },
  {
    key: 'normal', text: 'Normal', value: 'priority: normal', color: { backgroundColor: '#d93f0b', color: 'white' }
  },
  {
    key: 'low', text: 'Low', value: 'priority: low', color: { backgroundColor: '#f9d0c4', color: 'black' }
  },
]);

class RepositoryIssues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTypes: ['bug'],
      selectedPriorities: [],
      withLabels: [],
      withoutLabels: ['hold'],
      issues: null,
      stats: {},
      exceededSLAStats: {},
      openInfo: true,
      labels: [],
      orderBySLA: true
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { data, client, match } = nextProps;

    if ((data.loading && !data.repository) || !data.repository || this.state.issues) return;

    let issues = data.repository.issues.nodes;
    let { pageInfo } = data.repository.issues;

    while (pageInfo.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, after: $after, states: OPEN) {
              nodes {
                id
                number
                title
                createdAt
                url
                assignees (first: 10) {
                  nodes {
                    login
                    avatarUrl
                  }
                }
                labels (first: 10) {
                  nodes {
                    name
                    color
                  }
                }
                ... IssueItem
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
        ${IssueItem.fragments.issue}
        `,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfo.endCursor
        }
      });

      pageInfo = response.data.repository.issues.pageInfo;
      issues = issues.concat(response.data.repository.issues.nodes);
    }

    issues = issues.map(issue => utils.trackIssueComments(utils.trackIssueLabels(utils.getIssueSLA(issue))));

    const stats = this.getStats(issues);
    const exceededSLAStats = this.getStats(utils.filterIssuesBySLA(issues));

    const labels = data.repository.labels.nodes
      .filter(label => !['priority: high', 'priority: normal', 'priority: low', 'bug', 'enhancement', 'feature'].includes(label.name))
      .map(label => ({
        key: label.name,
        text: label.name,
        value: label.name,
        color: {
          color: 'white',
          backgroundColor: '#' + label.color
        }
      }));

    this.setState({
      issues, stats, exceededSLAStats, labels
    });
  }

  getStats = (issues, filterWith, filterWithout) => {
    const stats = {};

    if (filterWith) {
      issues = utils.filterIssuesWithLabel(filterWith, issues);
    }

    if (filterWithout) {
      issues = utils.filterIssuesWithoutLabel(filterWithout, issues);
    }

    stats.allIssues = issues;
    stats.codedIssues = utils.filterIssuesWithLabel(['coded'], issues);
    stats.passedIssues = utils.filterIssuesWithLabel(['passed'], issues);
    stats.reviewIssues = utils.filterIssuesWithLabel(['review'], issues);
    stats.betaIssues = utils.filterIssuesWithLabel(['beta'], issues);

    return stats;
  }

  renderStats = (stats, exceededSLAStats) => {
    const { openInfo, orderBySLA } = this.state;

    return (
      <Segment secondary>
        <Checkbox
          checked={openInfo}
          onChange={() => this.setState({ openInfo: !openInfo })}
          label="Show info"
          style={{ padding: '0em 1em' }}
        />
        <Checkbox
          checked={orderBySLA}
          onChange={() => this.setState({ orderBySLA: !orderBySLA })}
          label="Order by SLA"
          style={{ padding: '0em 1em' }}
        />
        {
      openInfo
        && (
        <div style={{ display: 'flex', paddingTop: '1em' }}>
          <div style={{ width: '50%', padding: '0em 1em' }}>
            <span style={{ ...styles.title }}>All issues</span>
            <Divider />
            <div>
              <span style={{ fontWeight: 'bold' }}>Total</span>
              :
              {' '}
              {stats.allIssues.length}
            </div>
            <div>
              <span style={{ ...styles.coded }}>Coded</span>
              :
              {' '}
              {stats.codedIssues.length}
            </div>
            <div>
              <span style={{ ...styles.passed }}>Passed</span>
              :
              {' '}
              {stats.passedIssues.length}
            </div>
            <div>
              <span style={{ ...styles.review }}>Review</span>
              :
              {' '}
              {stats.reviewIssues.length}
            </div>
            <div>
              <span style={{ ...styles.beta }}>Beta</span>
              :
              {' '}
              {stats.betaIssues.length}
            </div>
          </div>
          <div style={{ width: '50%', padding: '0em 1em' }}>
            <span style={{ ...styles.title }}>Issues that exceeded SLA</span>
            <Divider />
            <div>
              <span style={{ fontWeight: 'bold' }}>Total</span>
              :
              {' '}
              {exceededSLAStats.allIssues.length}
            </div>
            <div>
              <span style={{ ...styles.coded }}>Coded</span>
              :
              {' '}
              {exceededSLAStats.codedIssues.length}
            </div>
            <div>
              <span style={{ ...styles.passed }}>Passed</span>
              :
              {' '}
              {exceededSLAStats.passedIssues.length}
            </div>
            <div>
              <span style={{ ...styles.review }}>Review</span>
              :
              {' '}
              {exceededSLAStats.reviewIssues.length}
            </div>
            <div>
              <span style={{ ...styles.beta }}>Beta</span>
              : ---
            </div>
          </div>
        </div>
        )
    }
      </Segment>
    );
  }

  renderLabel = label => ({
    style: label.color,
    content: `${label.text}`
  })

  render() {
    const { issues } = this.state;

    if (issues === null) {
      return (
        <div id="RepositoryIssues">
          Loading...
        </div>
      );
    }

    const {
      stats, exceededSLAStats, labels, selectedTypes, selectedPriorities, withLabels, withoutLabels,
      orderBySLA
    } = this.state;

    let filteredIssues = issues;
    let filteredStats = stats;
    let filteredExceededSLAStats = exceededSLAStats;

    if (selectedTypes.length) {
      filteredStats = this.getStats(filteredStats.allIssues, selectedTypes);
      filteredExceededSLAStats = this.getStats(filteredExceededSLAStats.allIssues, selectedTypes);
      filteredIssues = utils.filterIssuesWithLabel(selectedTypes, filteredIssues);
    }

    if (selectedPriorities.length) {
      filteredStats = this.getStats(filteredStats.allIssues, selectedPriorities);
      filteredExceededSLAStats = this.getStats(filteredExceededSLAStats.allIssues, selectedPriorities);
      filteredIssues = utils.filterIssuesWithLabel(selectedPriorities, filteredIssues);
    }

    if (withLabels.length) {
      filteredStats = this.getStats(filteredStats.allIssues, withLabels);
      filteredExceededSLAStats = this.getStats(filteredExceededSLAStats.allIssues, withLabels);
      filteredIssues = utils.filterIssuesWithLabel(withLabels, filteredIssues);
    }

    if (withoutLabels.length) {
      filteredStats = this.getStats(filteredStats.allIssues, null, withoutLabels);
      filteredExceededSLAStats = this.getStats(filteredExceededSLAStats.allIssues, null, withoutLabels);
      filteredIssues = utils.filterIssuesWithoutLabel(withoutLabels, filteredIssues);
    }
    if (orderBySLA) {
      filteredIssues.sort((a, b) => a.diff - b.diff);
    }
    return (
      <div id="RepositoryIssues">
        <div style={{ display: 'flex' }}>
          <Dropdown
            placeholder="Filter type"
            multiple
            selection
            value={selectedTypes}
            options={typeOptions()}
            onChange={(e, data) => this.setState({ selectedTypes: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Filter priority"
            multiple
            selection
            value={selectedPriorities}
            options={priorityOptions()}
            onChange={(e, data) => this.setState({ selectedPriorities: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="With labels"
            multiple
            selection
            search
            value={withLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withLabels: data.value })}
            renderLabel={this.renderLabel}
            style={{ marginRight: '12px' }}
          />
          <Dropdown
            placeholder="Without labels"
            multiple
            selection
            search
            value={withoutLabels}
            options={labels}
            onChange={(e, data) => this.setState({ withoutLabels: data.value })}
            renderLabel={this.renderLabel}
          />
        </div>
        {this.renderStats(filteredStats, filteredExceededSLAStats)}
        <List selection verticalAlign="middle" divided>
          {filteredIssues.map(issue => <IssueItem key={issue.id} {...issue} />)}
        </List>
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const RepositoryIssuesQuery = gql`query RepositoryNodeQuery($name: String!, $owner: String!) {
  repository(name: $name, owner: $owner) {
    labels (first: 40) {
      nodes {
        name
        color
      }
    }
    issues (first: 100, states: OPEN) {
      nodes {
        id
        number
        title
        createdAt
        url
        assignees (first: 10) {
          nodes {
            login
            avatarUrl
          }
        }
        labels (first: 10) {
          nodes {
            name
            color
          }
        }
        ... IssueItem
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
${IssueItem.fragments.issue}
`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const RepositoryIssuesWithData = graphql(RepositoryIssuesQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login
    }
  })
})(observer(RepositoryIssues));

export default inject('store', 'client')(RepositoryIssuesWithData);
