import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';
import qs from 'qs';

import '../../assets/App.css';

const menuOptions = () => [
  {
    key: 'dashboard',
    text: 'Dashboard',
    value: 'dashboard'
  },
  {
    key: 'users',
    text: 'Users',
    value: 'users'
  }
];

class OrganizationHeader extends Component {
  constructor(props) {
    super(props);

    let value = '';

    if (props.location.pathname.split('/').length > 3) {
      value = props.location.pathname.split('/')[3];
    }

    this.state = {
      value
    };
  }

  handleItemClick = (e, data) => {
    const { name } = data;

    this.setState({ value: name });

    const { match, history, selectedRepositories } = this.props;
    const { organization_login } = match.params;

    history.push(`/organizations/${organization_login}/${name}?${qs.stringify({ repos: selectedRepositories })}`);
  }

  render() {
    const { style } = this.props;
    const { value } = this.state;

    return (
      <Menu style={style}>
        {menuOptions().map(item => (
          <Menu.Item
            key={item.key}
            name={item.value}
            active={value === item.value}
            content={item.text}
            onClick={this.handleItemClick}
          />
        ))}
      </Menu>
    );
  }
}

export default inject('store')(observer(OrganizationHeader));
