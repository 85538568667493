/* eslint-disable no-await-in-loop */

import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import moment from 'moment';
import qs from 'qs';
import { uniqBy } from 'lodash';

import Dashboard from '../../components/Dashboard';

import '../../assets/App.css';

class OrganizationDashboard extends Dashboard {
  async componentWillReceiveProps(nextProps) {
    const { data, location } = nextProps;

    if ((data.loading && !data.repository) || !data.repository || this.state.issues || this.state.openIssues || this.state.closedIssues) return;

    let issues = [];
    let openIssues = [];
    let closedIssues = [];
    let labels = data.repository.labels.nodes;

    const { repos } = qs.parse(location.search.replace('?', ''));

    await Promise.all(repos.map(async (repo, i) => {
      if (!i) return;

      const response = await this.getRepoInfo(repo);

      labels = labels.concat(response.labels.nodes);
    }));

    await Promise.all(repos.map(async (repo) => {
      const response = await this.getRepoIssues(repo);

      issues = issues.concat(response.issues);
      openIssues = openIssues.concat(response.openIssues);
      closedIssues = closedIssues.concat(response.closedIssues);
    }));

    const openStats = this.getStats(openIssues);
    const closedStats = this.getStats(closedIssues);

    labels = uniqBy(labels, 'name').filter(label => !['priority: high', 'priority: normal', 'priority: low', 'bug', 'enhancement', 'feature'].includes(label.name))
      .map(label => ({
        key: label.name,
        text: label.name,
        value: label.name,
        color: {
          color: 'white',
          backgroundColor: '#' + label.color
        }
      }));

    this.setState({
      issues, openIssues, openStats, closedIssues, closedStats, labels
    });
  }

  getRepoInfo = async (repo) => {
    const { client, match } = this.props;

    const res = await client.query({
      query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!) {
        repository(name: $name, owner: $owner) {
          labels (first: 40) {
            nodes {
              name
              color
            }
          }
        }
      }`,
      variables: {
        name: repo,
        owner: match.params.organization_login
      }
    });

    return res.data.repository;
  }

  getRepoIssues = async (repo) => {
    const { client, match } = this.props;

    const res = await client.query({
      query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!, $since: DateTime) {
        repository(name: $name, owner: $owner) {
          labels (first: 40) {
            nodes {
              name
              color
            }
          }
          issues (first: 100, states: OPEN) {
            nodes {
              labels (first: 10) {
                nodes {
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          open: issues (first: 100, filterBy: { since: $since }) {
            nodes {
              createdAt
              labels (first: 10) {
                nodes {
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          closed: issues (first: 100, states: CLOSED, filterBy: { since: $since }) {
            nodes {
              closedAt
              labels (first: 10) {
                nodes {
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }`,
      variables: {
        name: repo,
        owner: match.params.organization_login,
        since: moment().subtract(14, 'month').date(1).format()
      }
    });

    let issues = res.data.repository.issues.nodes;
    let { pageInfo } = res.data.repository.issues;

    let openIssues = res.data.repository.open.nodes;
    let { pageInfo: pageInfoOpen } = res.data.repository.open;

    let closedIssues = res.data.repository.closed.nodes;
    let { pageInfo: pageInfoClosed } = res.data.repository.closed;

    while (pageInfo.hasNextPage && pageInfoOpen.hasNextPage && pageInfoClosed.hasNextPage) {
      // Wait some time so github api won't deny the requests
      await new Promise(r => setTimeout(r, 200));

      try {
        const response = await client.query({
          query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!, $after: String, $afterOpen: String, $afterClosed: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, states: OPEN, after: $after) {
              nodes {
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
            open: issues (first: 100, after: $afterOpen, filterBy: { since: $since }) {
              nodes {
                createdAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
            closed: issues (first: 100, after: $afterClosed, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                closedAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
          variables: {
            name: repo,
            owner: match.params.organization_login,
            after: pageInfo.endCursor,
            afterOpen: pageInfoOpen.endCursor,
            afterClosed: pageInfoClosed.endCursor,
            since: moment().subtract(14, 'month').date(1).format()
          }
        });

        ({ pageInfo } = response.data.repository.issues);
        issues = issues.concat(response.data.repository.issues.nodes);

        pageInfoOpen = response.data.repository.open.pageInfo;
        openIssues = openIssues.concat(response.data.repository.open.nodes);

        pageInfoClosed = response.data.repository.closed.pageInfo;
        closedIssues = closedIssues.concat(response.data.repository.closed.nodes);
      } catch (e) {
        console.log(e);

        // Wait some time so github api won't deny the requests
        await new Promise(r => setTimeout(r, 10000));
      }
    }

    while (pageInfo.hasNextPage) {
      // Wait some time so github api won't deny the requests
      await new Promise(r => setTimeout(r, 200));

      try {
        const response = await client.query({
          query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!, $after: String) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, states: OPEN, after: $after) {
              nodes {
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
          variables: {
            name: repo,
            owner: match.params.organization_login,
            after: pageInfo.endCursor
          }
        });

        ({ pageInfo } = response.data.repository.issues);
        issues = issues.concat(response.data.repository.issues.nodes);
      } catch (e) {
        console.log(e);

        // Wait some time so github api won't deny the requests
        await new Promise(r => setTimeout(r, 10000));
      }
    }

    while (pageInfoOpen.hasNextPage) {
      // Wait some time so github api won't deny the requests
      await new Promise(r => setTimeout(r, 200));

      try {
        const response = await client.query({
          query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            open: issues (first: 100, after: $after, filterBy: { since: $since }) {
              nodes {
                createdAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
          variables: {
            name: repo,
            owner: match.params.organization_login,
            after: pageInfoOpen.endCursor,
            since: moment().subtract(14, 'month').date(1).format()
          }
        });

        pageInfoOpen = response.data.repository.open.pageInfo;
        openIssues = openIssues.concat(response.data.repository.open.nodes);
      } catch (e) {
        console.log(e);

        // Wait some time so github api won't deny the requests
        await new Promise(r => setTimeout(r, 10000));
      }
    }

    while (pageInfoClosed.hasNextPage) {
      // Wait some time so github api won't deny the requests
      await new Promise(r => setTimeout(r, 200));

      try {
        const response = await client.query({
          query: gql`query OrganizationDashboardQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            closed: issues (first: 100, after: $after, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                closedAt
                labels (first: 10) {
                  nodes {
                    name
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
          variables: {
            name: repo,
            owner: match.params.organization_login,
            after: pageInfoClosed.endCursor,
            since: moment().subtract(14, 'month').date(1).format()
          }
        });

        pageInfoClosed = response.data.repository.closed.pageInfo;
        closedIssues = closedIssues.concat(response.data.repository.closed.nodes);
      } catch (e) {
        console.log(e);

        // Wait some time so github api won't deny the requests
        await new Promise(r => setTimeout(r, 10000));
      }
    }

    return { issues, openIssues, closedIssues };
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const OrganizationDashboardQuery = gql`query OrganizationDashboardQuery($name: String!, $owner: String!) {
  repository(name: $name, owner: $owner) {
    labels (first: 40) {
      nodes {
        name
        color
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const OrganizationDashboardWithData = graphql(OrganizationDashboardQuery, {
  options: ownProps => ({
    variables: {
      name: qs.parse(ownProps.location.search.replace('?', '')).repos[0],
      owner: ownProps.match.params.organization_login
    }
  })
})(observer(OrganizationDashboard));

export default inject('store', 'client')(OrganizationDashboardWithData);
