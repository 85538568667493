import { observer } from 'mobx-react';

// Default store structure
const defaultStore = {
  token: null,
  user: null,
  currentOrganization: null
};

const createStore = () => observer(defaultStore);

export default createStore;
