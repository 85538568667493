/* eslint-disable no-await-in-loop */

import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import moment from 'moment';

import Users from '../../components/Users';


class RepositoryUsers extends Users {
  async componentWillReceiveProps(nextProps) {
    const { data, client, match } = nextProps;

    if ((data.loading && !data.repository) || !data.repository || this.state.issues) return;

    let issues = data.repository.issues.nodes;
    let { pageInfo } = data.repository.issues;

    while (pageInfo.hasNextPage) {
      const response = await client.query({
        query: gql`query RepositoryNodeQuery($name: String!, $owner: String!, $after: String, $since: DateTime) {
          repository(name: $name, owner: $owner) {
            issues (first: 100, after: $after, states: CLOSED, filterBy: { since: $since }) {
              nodes {
                number
                createdAt
                closedAt
                assignees (first: 10) {
                  nodes {
                    login
                    avatarUrl
                  }
                }
                timelineItems(first: 40, itemTypes: LABELED_EVENT) {
                  nodes {
                    ... on LabeledEvent {
                      label {
                        name
                      }
                      createdAt
                    }
                  }
                }
                assignedEvent: timelineItems(first: 40, itemTypes: ASSIGNED_EVENT) {
                  nodes {
                    ... on AssignedEvent {
                      assignee {
                        ... on User {
                          login
                        }
                      }
                      createdAt
                    }
                  }
                }
                labels (first: 10) {
                  nodes {
                    name
                    color
                  }
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }`,
        variables: {
          name: match.params.repository_name,
          owner: match.params.organization_login,
          after: pageInfo.endCursor,
          since: moment().subtract(3, 'month').date(1).format()
        }
      });

      pageInfo = response.data.repository.issues.pageInfo;
      issues = issues.concat(response.data.repository.issues.nodes);
    }

    issues = issues.map(issue => this.getIssueInfo(issue));

    const repoStats = this.getRepoStats(issues);
    let users = this.getIssuesUsers(issues);

    users = users.map(user => this.getUserStats(user, issues)).filter(user => user.stats.thisMonth.allIssues.length || user.stats.oneMonthAgo.allIssues.length || user.stats.twoMonthsAgo.allIssues.length);

    const labels = data.repository.labels.nodes
      .filter(label => !['priority: high', 'priority: normal', 'priority: low', 'bug', 'enhancement', 'feature'].includes(label.name))
      .map(label => ({
        key: label.name,
        text: label.name,
        value: label.name,
        color: {
          color: 'white',
          backgroundColor: '#' + label.color
        }
      }));

    this.setState({
      repoStats, issues, users, labels
    });
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const RepositoryUsersQuery = gql`query RepositoryUsersQuery($name: String!, $owner: String!, $since: DateTime) {
  repository(name: $name, owner: $owner) {
    labels (first: 40) {
      nodes {
        name
        color
      }
    }
    issues (first: 100, states: CLOSED, filterBy: { since: $since }) {
      nodes {
        number
        createdAt
        closedAt
        assignees (first: 10) {
          nodes {
            login
            avatarUrl
          }
        }
        timelineItems(first: 40, itemTypes: LABELED_EVENT) {
          nodes {
            ... on LabeledEvent {
              label {
                name
              }
              createdAt
            }
          }
        }
        assignedEvent: timelineItems(first: 40, itemTypes: ASSIGNED_EVENT) {
          nodes {
            ... on AssignedEvent {
              assignee {
                ... on User {
                  login
                }
              }
              createdAt
            }
          }
        }
        labels (first: 10) {
          nodes {
            name
            color
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const RepositoryUsersWithData = graphql(RepositoryUsersQuery, {
  options: ownProps => ({
    variables: {
      name: ownProps.match.params.repository_name,
      owner: ownProps.match.params.organization_login,
      since: moment().subtract(3, 'month').date(1).format()
    }
  })
})(observer(RepositoryUsers));

export default inject('store', 'client')(RepositoryUsersWithData);
