import moment from 'moment';
import { cloneDeep } from 'lodash';

const utils = {};

utils.filterAssignedIssues = (login, issues) => issues.filter(issue => !!issue.assignees.nodes.find(assignee => assignee.login === login) || (login === 'any' && !!issue.assignees.nodes.length));

utils.filterAnyAssigneeIssues = issues => issues.filter(issue => !!issue.assignedEvent.nodes.length);

utils.filterIssuesWithLabel = (target, issues) => issues.filter(issue => !!issue.labels.nodes.find(label => !!target.includes(label.name)));

utils.filterIssuesWithoutLabel = (target, issues) => issues.filter(issue => !issue.labels.nodes.find(label => !!target.includes(label.name)));

const SLA = {
  'priority: high': 4,
  'priority: normal': 25,
  'priority: low': 35
};
utils.SLA = SLA;

const getIssueSLA = (issue) => {
  const node = cloneDeep(issue);

  const today = moment();
  const label = issue.labels.nodes.find(l => !!Object.keys(SLA).includes(l.name));
  const sla = {
    diff: today.diff(moment(issue.createdAt), 'days')
  };

  node.diff = !label ? sla.diff : SLA[label.name] - sla.diff;

  return node;
};
utils.getIssueSLA = getIssueSLA;

utils.filterIssuesBySLA = (issues) => {
  const today = moment();

  return issues.filter((issue) => {
    const label = issue.labels.nodes.find(l => !!Object.keys(SLA).includes(l.name));

    if (!label) return false;

    const diff = today.diff(moment(issue.createdAt), 'days');

    return SLA[label.name] < diff;
  });
};

utils.trackIssueLabels = (issue) => {
  const timeline = {};
  const labelsCount = {};
  const timelineAssign = {};

  issue.timelineItems.nodes.forEach((item) => {
    timeline[item.label.name] = item.createdAt;

    if (!timeline[item.label.name + 'First']) {
      timeline[item.label.name + 'First'] = item.createdAt;
    }

    if (!labelsCount[item.label.name]) {
      labelsCount[item.label.name] = 1;
    } else {
      labelsCount[item.label.name] += 1;
    }
  });

  issue.assignedEvent.nodes.forEach((item) => {
    timelineAssign[item.assignee.login] = item.createdAt;

    if (!timelineAssign[item.assignee.login + 'First']) {
      timelineAssign[item.assignee.login + 'First'] = item.createdAt;
    }
  });

  issue.timelineTracking = timeline;
  issue.labelsCount = labelsCount;
  issue.timelineAssign = timelineAssign;

  return issue;
};

utils.trackIssueComments = (issue) => {
  const tags = {};

  issue.comments.nodes.forEach((item) => {
    let issueTags = item.bodyText.match(/\[(.*?)\]/g);

    if (!issueTags) return;

    issueTags = issueTags.map(tag => tag.replace('[', '').replace(']', '').toLowerCase());

    issueTags.forEach((tag) => {
      if (!tags[tag]) {
        tags[tag] = 1;
      } else {
        tags[tag] += 1;
      }
    });
  });

  issue.tags = tags;

  return issue;
};

export default utils;
