import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Icon, Dropdown } from 'semantic-ui-react';

import '../../assets/App.css';

const dropdownOptions = () => [
  {
    key: 'milestones',
    text: 'Milestones',
    value: 'milestones'
  },
  {
    key: 'issues',
    text: 'Issues',
    value: 'issues'
  },
  {
    key: 'dashboard',
    text: 'Dashboard',
    value: 'dashboard'
  },
  {
    key: 'users',
    text: 'Users',
    value: 'users'
  }
];

class RepositoryHeader extends Component {
  constructor(props) {
    super(props);

    let value = 'issues';

    if (props.location.pathname.split('/').length > 5) {
      value = props.location.pathname.split('/')[5];
    }

    this.state = {
      value
    };
  }

  onChangeDropdownValue = (e, data) => {
    const { value } = data;

    this.setState({ value });

    const { match, history } = this.props;
    const { repository_name, organization_login } = match.params;

    history.push(`/organizations/${organization_login}/repositories/${repository_name}/${value}`);
  }

  render() {
    const { match } = this.props;
    const { repository_name } = match.params;
    const { value } = this.state;

    return (
      <Header as="h2">
        <Header.Content>
          {repository_name}
          <Icon name="angle right" size="small" />
          <Dropdown
            inline
            options={dropdownOptions()}
            value={value}
            onChange={this.onChangeDropdownValue}
          />
        </Header.Content>
      </Header>
    );
  }
}

export default inject('store')(observer(RepositoryHeader));
