/* eslint-disable no-await-in-loop */

import React, { Component } from 'react';
import { gql } from 'react-apollo';
import {
  List, Label, Image
} from 'semantic-ui-react';

import '../../assets/App.css';

export default class IssueItem extends Component {
  static fragments = {
    issue: gql`
      fragment IssueItem on Issue {
        id
        number
        title
        createdAt
        url
        assignees (first: 10) {
          nodes {
            login
            avatarUrl
          }
        }
        comments (first: 40) {
          nodes {
            bodyText
          }
        }
        labels (first: 10) {
          nodes {
            name
            color
          }
        }
        timelineItems(first: 40, itemTypes: LABELED_EVENT) {
          nodes {
            ... on LabeledEvent {
              label {
                name
              }
            }
          }
        }
        assignedEvent: timelineItems(first: 40, itemTypes: ASSIGNED_EVENT) {
          nodes {
            ... on AssignedEvent {
              assignee {
                ... on User {
                  login
                }
              }
              createdAt
            }
          }
        }
      }`,
  }

  render() {
    const {
      url, number, title, diff, labels, assignees, labelsCount, tags, simple
    } = this.props;

    return (
      <List.Item as="a" href={url} target="_blank" rel="noreferrer noopener">
        <List.Content>
          <List.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {number}
              {' '}
-
              {' '}
              {title}
              {' '}
              {
                !simple && [
                  <Label
                    key="diff"
                    size="mini"
                    color={diff < 0 ? 'red' : (diff === 0 ? 'blue' : 'green')}
                  >
                    {diff}
                  </Label>,
                  labelsCount && !!labelsCount.review
                && (
                  <Label
                    key="reviews"
                    size="mini"
                    color="yellow"
                  >
                    Reviews:
                    {' '}
                    {labelsCount.review}
                  </Label>
                ),
                  tags && !!tags.suporte
                && (
                  <Label
                    key="tickets"
                    size="mini"
                    color="violet"
                  >
                    Chamados:
                    {' '}
                    {tags.suporte}
                  </Label>
                )
                ]
              }
            </div>
            {
              !simple && (
              <div>
                {labels.nodes.map(label => <Label key={label.name} style={{ color: 'white', backgroundColor: '#' + label.color }}>{label.name}</Label>)}
                {
                      assignees.nodes.length
                        ? (
                          <span style={{ paddingLeft: '1em' }}>
                            {assignees.nodes.map(assignee => <Image key={assignee.login} circular avatar src={assignee.avatarUrl} />)}
                          </span>
                        )
                        : null
                    }
              </div>
              )
            }
          </List.Header>
        </List.Content>
      </List.Item>
    );
  }
}
