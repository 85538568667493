import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { gql, graphql } from 'react-apollo';
import { Card, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import '../../assets/App.css';

class UserOrganizations extends Component {
  render() {
    const { data } = this.props;

    if ((data.loading && !data.viewer) || !data.viewer) {
      return (
        <div id="UserOrganizations">
          Loading...
        </div>
      );
    }

    const { organizations } = data.viewer;

    return (
      <div id="UserOrganizations">
        {organizations.nodes.map(organization => (
          <Card
            key={organization.login}
            image={organization.avatarUrl}
            header={organization.name}
            description={organization.description}
            extra={(
              <Link to={`/organizations/${organization.login}`}>
                Go to
                {' '}
                {organization.name}
                {' '}
                <Icon name="angle right" />
              </Link>
            )}
          />
        ))}
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const UserOrganizationsQuery = gql`{
  viewer {
    organizations (first: 3) {
      nodes {
        avatarUrl
        login
        name
        description
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const UserOrganizationsWithData = graphql(UserOrganizationsQuery)(observer(UserOrganizations));

export default inject('store')(UserOrganizationsWithData);
