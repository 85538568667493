import React, { Component } from 'react';
import {
  Route, withRouter, Redirect, Switch
} from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withCookies } from 'react-cookie';

import Home from './Home';
import NotFound from './NotFound';
import AuthLogin from './Auth/Login';

import '../assets/App.css';

const PrivateRoute = ({ component: Component, props, ...rest }) => (
  <Route
    {...rest}
    render={() => (props.store.token ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: props.location }
        }}
      />
    ))
    }
  />
);

const PublicRoute = ({ component: Component, props, ...rest }) => (
  <Route
    {...rest}
    render={() => <Component {...props} />}
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkAuthorize: true
    };
  }

  componentDidMount() {
    const { cookies, store } = this.props;

    const token = cookies.get('token');

    if (token) {
      store.token = token;
    }

    this.setState({ checkAuthorize: false });
  }

  render() {
    const { checkAuthorize } = this.state;

    if (checkAuthorize) {
      return (
        <div className="App">
          <h1>Loading...</h1>
        </div>
      );
    }

    return (
      <div className="App">
        <Switch>
          <PublicRoute path="/auth/login" component={AuthLogin} props={this.props} />
          <PrivateRoute path="/" component={Home} props={this.props} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default inject('store')(withRouter(withCookies(observer(App))));
