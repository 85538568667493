/* eslint-disable global-require */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Link, Switch } from 'react-router-dom';
import { gql, graphql } from 'react-apollo';
import { Menu, Container, Image } from 'semantic-ui-react';

import Avatar from '../components/Avatar';

import UserOrganizations from './User/Organizations';
import OrganizationNode from './Organization/Node';

import '../assets/App.css';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data, store } = nextProps;

    if ((data.loading && !data.viewer) || !data.viewer) return;

    store.user = data.viewer;
  }

  logout = () => {
    const {
      cookies, history, store, api
    } = this.props;

    store.user = null;
    store.token = null;
    api.access_token = null;

    cookies.remove('token', { path: '/' });

    history.push('/auth/login');
  }

  render() {
    const { store } = this.props;
    const { user, currentOrganization } = store;

    if (!user) {
      return (
        <div className="Home">
          Loading...
        </div>
      );
    }

    const items = [];

    if (currentOrganization) {
      items.push(
        <Menu.Item key={items.length} as={Link} to={`/organizations/${currentOrganization.login}`}>
          <Avatar src={currentOrganization.avatarUrl} name={currentOrganization.name} />
        </Menu.Item>
      );
    }

    return (
      <div className="Home">
        <Menu fixed="top">
          <Menu.Item
            as={Link}
            to="/"
          >
            <Image src={require('../assets/images/flik.png')} size="mini" centered circular />
          </Menu.Item>
          { items.map(item => item) }
          <Menu.Item
            onClick={() => this.logout()}
            content="Logout"
            position="right"
          />
        </Menu>
        <div style={{ height: 55 }} />
        <Container style={{ marginTop: '40px' }}>
          <Route exact path="/" component={UserOrganizations} />
          <Switch>
            <Route path="/organizations/:organization_login" component={OrganizationNode} />
          </Switch>
        </Container>
      </div>
    );
  }
}

// Initialize GraphQL queries or mutations with the `gql` tag
const HomeQuery = gql`{
  viewer {
    avatarUrl
    name
    organizations (first: 3) {
      nodes {
        avatarUrl
        id
        name
      }
    }
  }
}`;

// We then can use `graphql` to pass the query results returned by MyQuery
// to MyComponent as a prop (and update them as the results change)
const HometWithData = graphql(HomeQuery)(observer(Home));

export default inject('store', 'api')(HometWithData);
