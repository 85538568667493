import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import '../../assets/App.css';

class AuthLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: ''
    };
  }

  componentDidMount() {
    const { cookies, store, history } = this.props;

    const token = cookies.get('token');
    if (token) {
      store.token = token;

      history.push('/');
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      api, store, cookies, history, location
    } = this.props;
    const { token } = this.state;

    try {
      store.token = token;
      api.access_token = token;

      cookies.set('token', store.token, { path: '/' });

      if (location && location.state && location.state.from && location.state.from.pathname) {
        history.push(location.state.from.pathname);
      } else {
        history.push('/');
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { token } = this.state;

    return (
      <div className="AuthLogin">
        <h1>Sign in to ClassHub.</h1>
        <h2>Enter your details below.</h2>
        <form onSubmit={this.handleSubmit}>
          <label>
            Github token:
            <input type="text" name="token" value={token} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}

export default inject('store', 'api')(observer(AuthLogin));
